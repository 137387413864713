import React, { Component } from "react";
import logo from "../../assets/img/logoite.png";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <div>
          <img
            src={logo}
            className="img-fluid"
            alt="Instituto Tlaxcalteca de Elecciones"
          />
        </div>
        <div>
          Dirección: Ex-Fábrica San Manuel S/N, Col. Barrio Nuevo C.P. 90640,
          San Miguel Contla, Santa Cruz Tlaxcala, Tlaxcala.
          <br />
          Horario: Lunes a Viernes de 9:00 a 16:00 hrs. Tel: 01 (246) 46 5 03 40
        </div>
      </div>
    );
  }
}

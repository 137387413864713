import React, { Component } from "react";
import "./DatosP.css";

const DatosP__path = require.context("../assets");

export default class DatosP extends Component {
  render() {
    return (
      <div className="DatosP">
        <div className="DatosP__container pt-5 pb-5">
          <h3 className="bottom-title">DATOS PERSONALES</h3>
          <p>
            En todo momento, y en especial durante esta pandemia, los datos
            personales recabados por el Instituto Tlaxcalteca de Elecciones se
            encuentran protegidos, te invitamos a conocer nuestro aviso de
            privacidad en:&nbsp;
            <a href="https://www.itetlax.org.mx/AvisosPrivacidad">
              https://www.itetlax.org.mx/AvisosPrivacidad
            </a>
          </p>
          <h3 className="bottom-title2">
            <br />
            <br />
            SITIOS DE INTERÉS
          </h3>
          <div className="Grid__folder">
            <a
              href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019"
              target="blank_"
            >
              <img
                src={DatosP__path(`./${"img/"}${"oms.png"}`)}
                className="img-fluid"
                alt="MedidasDeProteccieon"
              />
            </a>
            <a href="https://www.gob.mx/" target="blank_">
              <img
                src={DatosP__path(`./${"img/"}${"GobiernoDeMexico.png"}`)}
                className="img-fluid"
                alt="MedidasDeProteccion"
              />
            </a>
            <a
              href="https://datos.gob.mx/busca/dataset/informacion-referente-a-casos-covid-19-en-mexico"
              target="blank_"
            >
              <img
                src={DatosP__path(`./${"img/"}${"gobMx.jpg"}`)}
                className="img-fluid"
                alt="MedidasDeProteccion"
                href="www.google.com"
              />
            </a>
          </div>
          <h3 className="bottom-title"></h3>
          <p>
            El Instituto es un organismo público autónomo e independiente en su
            funcionamiento y decisiones, de carácter permanente, profesional en
            su desempeño y dotado de personalidad jurídica. La autonomía e
            independencia del Instituto tienen carácter político, jurídico,
            administrativo, financiero y presupuestal, y se expresan en la
            facultad de resolver con libertad y con una estructura orgánica
            propia los asuntos de su competencia, sin interferencia de otros
            poderes, órganos públicos y particulares, salvo las que se deriven
            de los medios de control que establecen las Constituciones Federal y
            Local, esta Ley y demás disposiciones aplicables.
          </p>
        </div>
      </div>
    );
  }
}

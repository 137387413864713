import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg bg-light" id="navbarCOVID19">
        <div className="container-fluid">
          <Link to="./" className="navbar-brand">
            Inicio
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="nav-link" to="./">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Acuerdos">
                  Acuerdos
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Infografias">
                  Infografías
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Contingencia">
                  ¿Qué hemos hecho en contingencia?
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Posicionamiento">
                  Posicionamiento
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Difusión">
                  Difusión
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Contacto">
                  ¿Dónde contactarnos?
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Transparencia">
                  Transparencia Proactiva
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

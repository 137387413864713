//APP
import { BrowserRouter as Router } from "react-router-dom";

//Layout
import Navbar from "./layout/Navbar/Navbar";
import Footer from "./layout/Footer/Footer";
import Home from "./pages/Home";

//Sections
import Acuerdos from "./pages/Acuerdos";
import "./App.css";

import Infografias from "./pages/Infografias";
import "./App.css";

import Contingencia from "./pages/Contingencia";
import "./App.css";

import Posicionamiento from "./pages/Posicionamiento";
import "./App.css";

import Difusion from "./pages/Difusion";
import "./App.css";

import Contacto from "./pages/Contacto";
import "./App.css";

import Transparencia from "./pages/Transparencia";
import "./App.css";

import DatosP from "./pages/DatosP";
import "./App.css";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="App__container">
        <Home />
        <Acuerdos />
        <Infografias />
        <Contingencia />
        <Posicionamiento />
        <Difusion />
        <Contacto />
        <Transparencia />
        <DatosP />
      </div>
      <Footer />
    </Router>
    //  <Router>
    //    <Navbar />
    //    <div className="App__container">
    //      <Routes>
    //        <Route exact path="/" element={<Home />} />
    //        <Route path="/Acuerdos" element={<Acuerdos />} />
    //      </Routes>
    //    </div>
    //    <Footer />
    //  </Router>
  );
}

export default App;

import React, { Component } from "react";
import "./Posicionamiento.css";

const Posicionamiento__path = require.context("../assets");

export default class Posicionamiento extends Component {
  render() {
    return (
      <div className="Posicionamiento">
        <div className="Posicionamiento__container pt-5 pb-5">
          <h3 className="bottom-title">POSICIONAMIENTO</h3>
          <p>
            En el marco de la contingencia sanitaria derivada de la pandemia del
            Covid 19, tanto instituciones como personas en lo individual,
            desarrollan acciones de apoyo económico, alimentario, médico y de
            cualquier tipo solidario en respaldo a la población vulnerable, por
            ello es necesario recordar que cualquier actividad debe estar
            inscrita en el marco de la ley.
          </p>
          <p>
            En el presente micrositio ponemos a tu disposición información sobre
            la actividad institucional durante esta etapa de emergencia
            sanitaria.
          </p>
          <p>
            Es imperativo evitar tentaciones y observar la obligación de aplicar
            con imparcialidad los recursos públicos que están bajo la
            responsabilidad de las autoridades, como lo establece el artículo
            134 de la Constitución General, en sus párrafos séptimo y octavo,
            que dicen: “Los servidores públicos de la Federación, las entidades
            federativas, los Municipios y las demarcaciones territoriales de la
            Ciudad de México, tienen en todo tiempo la obligación de aplicar con
            imparcialidad los recursos públicos que están bajo su
            responsabilidad, sin influir en la equidad de la competencia entre
            los partidos políticos”… los recursos deben “administrarse con
            eficiencia, eficacia, economía, transparencia y honradez para
            satisfacer los objetivos a los que estén destinados”.
          </p>
          <p>
            Recordar también que "la propaganda, bajo cualquier modalidad de
            comunicación social, que difundan como tales, los poderes públicos,
            los órganos autónomos, las dependencias y entidades de la
            administración pública y cualquier otro ente de los tres órdenes de
            gobierno, deberá tener carácter institucional y fines informativos,
            educativos o de orientación social. En ningún caso esta propaganda
            incluirá nombres, imágenes, voces o símbolos que impliquen promoción
            personalizada de cualquier servidor público”, como lo prevé el
            artículo 95, Apartado B, párrafo tercero, de la Constitución Local.
          </p>
          <p>
            En este contexto, el Instituto Tlaxcalteca de Elecciones hace un
            llamado a servidoras y servidores públicos, autoridades de los tres
            niveles de gobierno, partidos políticos, organismos autónomos y
            actores políticos y sociales en general, a evitar cualquier acción
            que pudiera constituir una violación a la normatividad electoral.
          </p>
          <p>
            En este complejo escenario social que nos toca vivir, es necesario
            actuar de forma congruente y asumir con absoluta responsabilidad
            democrática nuestro papel.
          </p>
          <p>
            La pandemia puede ser una oportunidad para que las instituciones se
            fortalezcan ante la ciudadanía y, con sus acciones, contribuyan al
            robustecimiento de la democracia, basadas en el absoluto respeto a
            una sociedad madura, crítica, reflexiva y exigente; recordando la
            existencia de un marco legal que debe observarse y de una sociedad
            que censura cualquier comportamiento anómalo.
          </p>
          <div className="Grid__folderPos">
            <div className="ms-auto w-50 p-2 PosicionamientoPar">
              <p>
                <br />
                <br />
                Mtra. Elizabeth Piedras Martínez
                <br />
                Consejera Presidenta
                <br />
                Sesión de Consejo General
                <br />
                22 mayo de 2020
              </p>
            </div>
            <div className="Grid__folderPosN">
              <img
                src={Posicionamiento__path(`./${"img/"}${"mujer.png"}`)}
                className="img-fluid"
                alt="#QuedateEnCasa"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

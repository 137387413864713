import React, { Component } from "react";
import "./Transparencia.css";

const Transparencia__path = require.context("../assets");

export default class Transparencia extends Component {
  render() {
    return (
      <div className="Transparencia">
        <div className="Transparencia__container pt-5 pb-5">
          <h3 className="bottom-title">¿QUÉ HEMOS HECHO EN TRANSPARENCIA?</h3>
          <div className="Transparencia__Negro">
            <p>
              Conoce el monto de recursos económicos que hemos ejercido hasta
              ahora en acciones destinadas a enfrentar la pandemia y en qué se
              han gastado:
            </p>
          </div>
          <div className="Grid__folder">
            <img
              src={Transparencia__path(`./${"img/"}${"tp2021.jpg"}`)}
              className="img-fluid"
              alt="MedidasDeProteccieon"
            />
            <img
              src={Transparencia__path(`./${"img/"}${"tp2020.jpg"}`)}
              className="img-fluid"
              alt="MedidasDeProteccion"
            />
            <div className="Transparencia__Negro">
              <p>
                <b>Fuente:</b> Dirección de Prerrogativas Administración y
                Fiscalización
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInternetExplorer,
} from "@fortawesome/free-brands-svg-icons";

import "./Contacto.css";
const Contacto__path = require.context("../assets");
export default class Contacto extends Component {
  render() {
    return (
      <div className="Contacto">
        <div className="Contacto__container pt-5 pb-5">
          <h3 className="bottom-title">¿DÓNDE CONTACTARNOS?</h3>
          <div className="Contacto__box">
            <a
              href="https://www.facebook.com/ITETlax/"
              className="btn btn-lg ms-4 me-4"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://twitter.com/ITETLAX?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              className="btn btn-lg ms-4 me-4"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCUBfUX_c54NfhhvZRzK0k1w/feed?app=desktop"
              className="btn btn-lg ms-4 me-4"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              href="https://www.itetlax.org.mx/"
              className="btn btn-lg ms-4 me-4"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInternetExplorer} />
            </a>
          </div>
          <div className="mx-auto w-75">
            <img
              src={Contacto__path(`./${"img/"}${"contacto.jpeg"}`)}
              className="img-fluid"
              alt="MedidasDeProteccion"
            />
            <p className="Contacto__title">MedidasDeProteccion</p>
          </div>
        </div>
      </div>
    );
  }
}

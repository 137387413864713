import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import "./Acuerdos.css";

const Acuerdos__path = require.context("../assets");

export default class Acuerdos extends Component {
  render() {
    return (
      <div className="Acuerdos">
        <div className="Acuerdos__container">
          <h3 className="bottom-title">ACUERDOS</h3>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="Acuerdos__box">
                <a
                  href={Acuerdos__path(`./${"pdf/acuerdos/"}${"1.pdf"}`)}
                  className="btn btn-danger ms-2 me-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </a>{" "}
                ITE-CG 16/2020
              </div>
              <div className="Acuerdos__body ps-3 pe-3 pt-3 b-3">
                ACUERDO DEL CONSEJO GENERAL DEL INSTITUTO TLAXCALTECA DE
                ELECCIONES, POR EL QUE SE APRUEBAN LAS MEDIDAS PARA GARANTIZAR
                EL FUNCIONAMIENTO DEL INSTITUTO Y PREVENCIÓN DE LA SALUD DE LAS
                Y LOS SERVIDORES PÚBLICOS Y PERSONAS QUE ACUDAN A SUS
                INSTALACIONES, CON MOTIVO DE LA PANDEMIA COVID-19.
              </div>
              <div className="Acuerdos__box"> </div>
            </div>
            <div className="col-md-6">
              <div className="Acuerdos__box">
                <a
                  href={Acuerdos__path(`./${"pdf/acuerdos/"}${"1.pdf"}`)}
                  className="btn btn-danger ms-2 me-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </a>{" "}
                ITE-CG 17/2020
              </div>
              <div className="Acuerdos__body ps-3 pe-3 pt-3 b-3">
                ACUERDO DEL CONSEJO GENERAL DEL INSTITUTO TLAXCALTECA DE
                ELECCIONES, POR EL QUE SE MODIFICAN Y AMPLÍAN LAS MEDIDAS
                APROBADAS MEDIANTE ACUERDO ITE-CG 16/2020, EN CUMPLIMIENTO A LO
                ORDENADO POR EL CONSEJO DE SALUBRIDAD GENERAL.
              </div>
              <div className="Acuerdos__box">
                <a
                  href={Acuerdos__path(`./${"pdf/acuerdos/"}${"1.pdf"}`)}
                  className="btn btn-danger ms-2 me-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </a>{" "}
                Anexo ITE-CG 17/2020
              </div>
            </div>
          </div>
          <div className="w-100 mt-3">
            <div className="Acuerdos__box">
              <a
                href={Acuerdos__path(`./${"pdf/acuerdos/"}${"1.pdf"}`)}
                className="btn btn-danger ms-2 me-2"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFilePdf} />
              </a>{" "}
              ITE-CG 23/2020
            </div>
            <div className="Acuerdos__body ps-3 pe-3 pt-3 b-3">
              ACUERDO DEL CONSEJO GENERAL DEL INSTITUTO TLAXCALTECA DE
              ELECCIONES, POR EL QUE SE ACTUALIZAN LAS MEDIDAS CON LAS QUE
              CUENTA EL INSTITUTO CON MOTIVO DE LA PANDEMIA COVID-19, PARA EL
              REGRESO DE ACTIVIDADES PRESENCIALES DEL INSTITUTO TLAXCALTECA DE
              ELECCIONES, GARANTIZANDO LA PROTECCIÓN DE LAS SERVIDORAS Y
              SERVIDORES PÚBLICOS, ASÍ COMO EL FUNCIONAMIENTO DEL MISMO,
              DERIVADO DE LA ESTRATEGIA IMPLEMENTADA POR LA SECRETARÍA DE SALUD,
              PARA LA REAPERTURA DE LAS ACTIVIDADES SOCIALES, EDUCATIVAS Y
              ECONÓMICAS, ASÍ COMO UN SISTEMA DE SEMÁFORO POR REGIONES PARA
              EVALUAR SEMANALMENTE EL RIESGO EPIDEMIOLÓGICO RELACIONADO CON LA
              REAPERTURA DE ACTIVIDADES EN CADA ENTIDAD FEDERATIVA Y SE
              ESTABLECEN ACCIONES EXTRAORDINARIAS.
            </div>
            <div className="Acuerdos__box"> </div>
          </div>
        </div>
      </div>
    );
  }
}

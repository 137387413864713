import React, { Component } from "react";
import "./Difusion.css";
const Difusion__path = require.context("../assets");
export default class Difusion extends Component {
  render() {
    return (
      <div className="Difusion">
        <div className="Difusion__container pt-5 pb-5">
          <h3 className="bottom-title">DIFUSION</h3>
          <div className="mx-auto w-75">
            <img
              src={Difusion__path(`./${"img/"}${"difusion.jpeg"}`)}
              className="img-fluid"
              alt="MedidasDeProteccion"
            />
            <p className="Difusion__title">MedidasDeProteccion</p>
          </div>
        </div>
      </div>
    );
  }
}

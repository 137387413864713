import React, { Component } from "react";
import "./Contingencia.css";

const Contingencia__path = require.context("../assets");

export default class Contingencia extends Component {
  render() {
    return (
      <div className="Contingencia">
        <div className="Contingencia__container pt-5 pb-5">
          <h3 className="bottom-title">¿QUÉ HEMOS HECHO EN CONTINGENCIA?</h3>
          <div className="Grid__folder">
            <img
              src={Contingencia__path(`./${"img/"}${"quehace.jpg"}`)}
              className="img-fluid"
              alt="MedidasDeProteccieon"
            />
            <img
              src={Contingencia__path(`./${"img/"}${"eltrabajocontinua.jpg"}`)}
              className="img-fluid"
              alt="MedidasDeProteccion"
            />
          </div>
        </div>
      </div>
    );
  }
}

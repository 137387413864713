import React, { Component } from "react";
import "./Infografias.css";
import Carousel from "../layout/Carousel/Carousel";
import CarouselItemIMG from "../layout/Carousel/CarouselItemIMG";
const Infografias__path = require.context("../assets");
export default class Infografias extends Component {
  render() {
    return (
      <div className="Infografias">
        <div className="Infografias__container pt-5 pb-5">
          <h3 className="bottom-title">INFOGRAFIAS</h3>
          <Carousel idCarousel="hola">
            <CarouselItemIMG
              imgUrl="https://covid19.itetlax.org.mx/assets/img/mdp.jpg"
              alt="MedidasDeProteccion"
              itemActive="active"
              url=""
              linkToo=""
            />
            <CarouselItemIMG
              imgUrl="https://covid19.itetlax.org.mx/assets/img/infeccion.jpg"
              alt="ReducirInfeccion"
              itemActive=""
              url=""
              linkToo=""
            />
            <CarouselItemIMG
              imgUrl="https://covid19.itetlax.org.mx/assets/img/recomendaciones.jpg"
              alt="Recomendaciones"
              itemActive=""
              url=""
              linkToo=""
            />
          </Carousel>
          <div className="hr"></div>
        </div>
      </div>
    );
  }
}

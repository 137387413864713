import React, { Component } from "react";
import "./Home.css";

const Home__path = require.context("../assets");

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="Home__container pt-5 pb-5">
          <div className="w-25 mx-auto">
            <img
              className="img-fluid"
              src={Home__path(`./${"img/"}${"logoite.png"}`)}
              alt="Instituto Tlaxcalteca de Elecciones"
            />
          </div>
          <p className="Home__title">Instituto Tlaxcalteca de Elecciones</p>
          <p className="Home__title-2">CONTINGENCIA COVID-19</p>
          <div className="ms-auto w-25">
            <img
              src={Home__path(`./${"img/"}${"coronavirus.png"}`)}
              className="img-fluid Home__coronavirus w-50"
              alt="Coronavirus"
            />
          </div>
          <h3 className="bottom-title">PRESENTACIÓN</h3>
          <p>
            Ante las medidas sanitarias dictadas por las autoridades mexicanas,
            derivadas de la estrategia para el combate de la pandemia por el
            Covid 19, el Instituto Tlaxcalteca de Elecciones adoptó las medidas
            necesarias para garantizar la salud de quienes ahí colaboramos y de
            quiénes acuden a nuestras instalaciones; así como la operatividad de
            este órgano.
          </p>
          <p>
            En el presente micrositio ponemos a tu disposición información sobre
            la actividad institucional durante esta etapa de emergencia
            sanitaria.
          </p>
          <div className="mx-auto w-25">
            <img
              src={Home__path(`./${"img/"}${"icon1.png"}`)}
              className="img-fluid"
              alt="#QuedateEnCasa"
            />
            <p className="Home__title">#QuedateEnCasa</p>
          </div>
        </div>
      </div>
    );
  }
}
